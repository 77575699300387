export const permissionMappings = {
    "all": "alles",
    "AvalClass": "Avalklassen",
    "AvalPremium": "Avalprämien",
    "Bond": "Limite",
    "ClientCompany": "Kunden",
    "ClientEmployee": "Kundenkonten",
    "ClientEmployeeRequest": "Zugangsanfragen",
    "Collateral": "Sicherheiten",
    "InsuranceCompany": "Versicherer",
    "InsurancePolicy": "Policen",
    "InsurancePolicyDetail": "Policen-Details",
    "InsuranceWebsite": "Versicherer-Webseiten",
    "Obligo": "Obligos",
    "PaymentType": "Zahlweisen",
    "Project": "Projekte",
    "QuotationRequest": "Angebotsanfragen",
    "Role": "Rollen",
    "SingleLimit": "Einzelstücke",
    "UpdateRequest": "Aktualisierungsanfragen",
    "User": "Nutzer",
    "UserRole": "Benutzerrollen",
    "approve": "genehmigen",
    "aval_classes": "einsehen",
    "cancel": "abbrechen",
    "change_password": "passwort ändern",
    "complete": "abschließen",
    "create": "erstellen",
    "decline": "ablehnen",
    "destroy": "löschen",
    "disable": "deaktivieren",
    "enable": "aktivieren",
    "index": "auflisten lassen",
    "latest": "einsehen",
    "list": "auflisten lassen",
    "manage": "verwalten",
    "pause": "pausieren",
    "read": "einsehen",
    "show": "einsehen",
    "start": "starten",
    "update": "anpassen"
}